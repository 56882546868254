<template>
  <div class="d-flex align-items-center">
    <div
      v-if="!code"
      class="flex-grow-1"
    >
      <Checkbox
        :value="value == 'true'"
        @update:value="$emit('input', $event ? 'true' : 'false')"
      />
    </div>
    <BFormTextarea
      v-else
      :value="value"
      :rows="1"
      size="sm"
      class="money-font"
      @input="$emit('input', $event)"
    />
    <div class="ml-2">
      <i
        class="fas hover-icon"
        :class="code ? 'fa-list-dropdown': 'fa-code'"
        @click="code = !code"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/utils/Checkbox';

export default {
  props: {
    value: String,
  },
  data: () => ({
    code: false,
  }),
  components: {
    Checkbox,
  },
  created() {
    this.code = this.value && (this.value !== 'true' && this.value !== 'false');
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
