<template>
  <div class="d-flex align-items-center">
    <slot v-if="!code">
      <BFormInput
        :value="textValue"
        @input="emitAsText"
      />
    </slot>
    <BFormTextarea
      v-else
      :value="value"
      :rows="1"
      size="sm"
      class="money-font"
      @input="$emit('input', $event)"
    />
    <div class="ml-2">
      <i
        class="fas hover-icon"
        :class="code ? 'fa-list-dropdown': 'fa-code'"
        @click="code = !code"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  data: () => ({
    code: false,
  }),
  computed: {
    textValue() {
      return (this.value || '').toString().replaceAll('"', '');
    },
  },
  methods: {
    emitAsText(value) {
      this.$emit('input', `"${value}"`);
    },
  },
  created() {
    const value = (this.value || '').toString();
    this.code = value
      && (!value.startsWith('"')
      || !value.endsWith('"')
      || (value.replaceAll('"', '').length + 2) !== value.length); // only 2x"
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
