<template>
  <div ref="container">
    <div
      class="position-relative"
      :style="{
        'margin-left': `${50 / list.length}%`,
        'margin-right': `${50 / list.length}%`,
      }"
    >
      <div
        class="mark"
        :style="{
          left: `${(sliderStep / (list.length - 1)) * 100}%`
        }"
      />

      <div
        v-for="(s, i) in list"
        :key="s.key"
        class="mark"
        :class="i <= sliderStep ? '' : 'mark-grey'"
        :style="{
          left: `${(i / (list.length - 1)) * 100}%`
        }"
      />
      <BProgress
        :value="sliderStep"
        :max="list.length - 1"
        class="mb-2"
        height="3px"
      />
    </div>

    <div class="d-flex justify-content-around">
      <div
        v-for="s in list"
        :key="s.key"
        class="col text-center slider-text"
        :class="isEnabled(s.key) ? '' : 'disabled'"
        @click="isEnabled(s.key) ? $emit('select', s.key) : null"
      >
        <Tippy
          :ref="`popup${s.key}`"
          trigger="manual"
          :hide-on-click="false"
          theme="light"
          arrow
          interactive
          sticky
          placement="bottom"
        >
          <template #trigger>
            {{ s.text }}
          </template>

          <div>
            <button
              type="button"
              aria-label="Close"
              class="close"
              @click="close"
            >
              ×
            </button>
          </div>

          <div class="font-weight-bold">
            {{ $t('form.weSavedYourWork') }}!
          </div>
          <div>
            <button
              class="btn btn-primary mt-2 btn-sm btn-block"
              @click="restore"
            >
              <i class="fas fa-check pr-2" />
              {{ $t('form.restore') }}
            </button>
          </div>
        </Tippy>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    page: String,
    enabled: {
      type: Array,
      default: () => [],
    },
    previousPage: String,
    previousBill: Object,
  },
  computed: {
    steps() {
      return this.list.map((x, i) => i);
    },
    sliderStep: {
      get() {
        return this.list.findIndex((x) => x.key === this.page);
      },
      set() {},
    },
  },
  watch: {
    previousPage(p) {
      if (!p) {
        this.list.forEach(({ key }) => {
          const refs = this.$refs[`popup${key}`];
          if (refs.length > 0) {
            refs[0].tippy().hide();
          }
        });
      }
    },
  },
  methods: {
    close() {
      const refs = this.$refs[`popup${this.previousPage}`];
      if (refs.length > 0) {
        refs[0].tippy().hide();
      }
      this.$emit('cancel-restore');
    },
    restore() {
      const refs = this.$refs[`popup${this.previousPage}`];
      if (refs.length > 0) {
        refs[0].tippy().hide();
      }
      this.$emit('restore');
    },
    isEnabled(key) {
      return this.page === key || this.enabled.some((y) => y === key);
    },
  },
  mounted() {
    if (this.previousPage) {
      setTimeout(() => {
        const refs = this.$refs[`popup${this.previousPage}`];
        if (refs.length > 0) {
          refs[0].tippy().show();
        }
      }, 200);
    }
  },
};
</script>

<style lang="scss" scoped>

  .mark {
    border-radius: 50%;
    background-color: $blue;
    border: 2px solid #fff;
    height: 15px;
    width: 15px;
    position: absolute;
    transform: translate(-50%, -40%);
    top: 0;
    left: 0;
    transition: left 300ms;
    z-index: 2;
  }

  .mark-grey {
    background-color: #e9ecef;
    z-index: 1;
  }

  .slider-text {
    font-weight: 400;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &.disabled {
      cursor: default;
      color: #888;
      text-decoration: none;
    }
  }
</style>
