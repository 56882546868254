<template>
  <Tippy
    ref="tippy"
    max-width="400"
    interactive
    :hide-on-click="false"
  >
    <template #trigger>
      <i
        class="secondary-icon"
        :class="{
          'fas fa-share-alt': !sharableLink,
          'fas fa-copy': sharableLink
        }"
        @click="generateOrCopyLink"
      />
    </template>

    <div v-if="!sharableLink">
      {{ $t('paymentStatus.generateLink') }}
    </div>

    <template v-else>
      <div class="small">
        {{ $t('paymentStatus.clickToCopy') }}
      </div>
      <div>
        <BFormInput
          type="text"
          :value="sharableLinkSuccess ? $t('paymentStatus.linkCopied') : sharableLink"
          readonly
          size="sm"
          class="money-font small mt-1 copy-url"
          @click="copyLink"
        />
      </div>
    </template>
  </Tippy>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    payerId: String,
  },
  data: () => ({
    sharableLinkPending: false,
    sharableLinkSuccess: false,
    sharableLink: null,
  }),
  methods: {
    ...mapActions([
      'generatePaymentLink',
    ]),
    generateOrCopyLink() {
      if (this.sharableLink) {
        this.copyLink();
      } else {
        this.generateLink();
      }
    },
    generateLink() {
      this.sharableLinkPending = true;
      this.generatePaymentLink({
        params: {
          query: {
            receiverIds: this.payerId,
          },
        },
      })
        .then(({ data }) => {
          this.$refs.tippy.tippy().show();
          this.sharableLink = data.url;
        })
        .finally(() => {
          this.sharableLinkPending = false;
        });
    },
    copyLink() {
      navigator.clipboard.writeText(this.sharableLink)
        .then(() => {
          this.sharableLinkSuccess = true;
          setTimeout(() => {
            this.$refs.tippy.tippy().hide();
            this.sharableLinkSuccess = false;
          }, 2000);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-url {
  cursor: pointer;
  font-size: 12px;
}
</style>
