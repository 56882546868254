<template>
  <div v-if="gatewaysPending">
    <Loader />
  </div>
  <div v-else-if="error">
    <span
      class="text-danger small"
    >
      {{ $t('error.settingsUnavailable') }}
    </span>
  </div>
  <div v-else>
    <div
      class="small text-secondary mb-3"
      style="margin-top: -5px"
      v-html="$t('settings.info.internalPayments')"
    />
    <EditableList
      ref="list"
      :list="gateways"
      :pending="pending"
      width="500"
      :hide-add="!isAdmin"
      :hide-remove="!isAdmin"
      :form-add-title="$t('settings.gateway.add')"
      :form-edit-title="$t('settings.gateway.edit')"
      @submit="$refs.form.submit()"
      @remove="$refs.form.remove()"
    >
      <template #listElement="{ row }">
        <div
          class="d-flex align-items-center w-100"
        >
          <div class="status-icon">
            <div
              v-if="row.enabled"
              v-tippy
              class="text-primary"
              :content="$t('settings.gateway.enabled')"
            >
              <i class="fas fa-check" />
            </div>
            <div
              v-else
              v-tippy
              class="text-danger"
              :content="$t('settings.gateway.disabled')"
            >
              <i class="fas fa-xmark" />
            </div>
          </div>
          <div
            class="mx-2"
            style="width: 80px"
          >
            <img
              src="@/assets/autopay.png"
              style="max-height: 30px; max-width: 80px;"
            >
          </div>
          <div
            class="pl-2 flex-grow-1"
          >
            <div>
              <div class="small">
                {{ $t('settings.paymentCommission') }}:
                <span
                  v-if="row.commissionType === 'OneForThousand'"
                  v-tippy
                  :content="$t('settings.commission.oneForThousandInfo')"
                >
                  {{ $t('settings.commission.oneForThousand') }}
                </span>
                <span v-else>
                  {{ Math.round(row.commissionValue * 10000) / 100 }} %
                </span>
              </div>
            </div>
            <div
              v-if="row.payeePaysCommission"
              class="small font-weight-bold"
            >
              {{ $t('settings.commission.companyPays') }}
            </div>
          </div>
          <div
            class="text-secondary"
          >
            <i
              v-if="row.isDefault"
              v-tippy
              :content="$t('general.default')"
              class="fas fa-star text-secondary"
            />
            <span v-else>
              {{ row.categoryIds.length }} x
              <i class="fa-regular fa-box-dollar" />
            </span>
          </div>
        </div>
      </template>
      <template #form="{edited}">
        <PaymentGatewayForm
          ref="form"
          :pending.sync="pending"
          :gateway="getGateway(edited)"
          @updated="updated"
          @added="gateways.push($event); close()"
          @removed="removed(edited)"
        />
      </template>
    </EditableList>
  </div>
</template>

<script>
import EditableList from '@/components/settings/EditableList';
import { mapActions, mapGetters } from 'vuex';
import PaymentGatewayForm from './PaymentGatewayForm';

export default {
  data: () => ({
    gateways: [],
    pending: false,
    gatewaysPending: false,
    error: false,
    updatePending: {},
  }),
  components: {
    EditableList,
    PaymentGatewayForm,
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {
    ...mapActions(['getGateways']),
    updated(gateway) {
      const index = this.gateways.findIndex((x) => x.id === gateway.id);
      this.$set(this.gateways, index, gateway);
      this.close();
    },
    removed(gatewayId) {
      this.gateways = this.gateways.filter((x) => x.id !== gatewayId);
      this.close();
    },
    close() {
      this.pending = false;
      this.$refs.list.close();
    },
    getGateway(id) {
      return this.gateways.find((x) => x.id === id);
    },
    request() {
      this.gatewaysPending = true;
      this.error = false;

      this.getGateways()
        .then(({ data }) => {
          this.gateways = data;
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.gatewaysPending = false;
        });
    },
  },
  created() {
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.gateway-box {
  border: 2px solid #eee;
  border-radius: 20px;
  padding: 10px;
}

.status-icon {
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  padding: 0 10px;

  i {
    font-size: 20px;
    display: block;
  }
}

.gateway-disabled {
  opacity: 0.5;
}
</style>
