<template>
  <div
    style="padding-top: 8px"
  >
    <label class="mb-1">
      {{ $t('settings.forms.formName') }}
    </label>
    <BFormInput
      v-model="name"
      class="mb-2"
      size="sm"
      :placeholder="$t('settings.forms.formName')"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    name: '',
    actionType: 'PayerCreate',
  }),
  computed: {
    ...mapGetters([
      'payeeId',
    ]),
  },
  methods: {
    ...mapActions(['createFormDefinition']),
    submit() {
      this.createFormDefinition({
        data: {
          name: this.name,
        },
      })
        .then(() => {
          this.$emit('refresh');
          this.$emit('close');
        });
    },
  },
};
</script>

<style>
.link {
    font-size: 12px;
}
</style>
