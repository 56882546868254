var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container"},[_c('div',{staticClass:"position-relative",style:({
      'margin-left': `${50 / _vm.list.length}%`,
      'margin-right': `${50 / _vm.list.length}%`,
    })},[_c('div',{staticClass:"mark",style:({
        left: `${(_vm.sliderStep / (_vm.list.length - 1)) * 100}%`
      })}),_vm._l((_vm.list),function(s,i){return _c('div',{key:s.key,staticClass:"mark",class:i <= _vm.sliderStep ? '' : 'mark-grey',style:({
        left: `${(i / (_vm.list.length - 1)) * 100}%`
      })})}),_c('BProgress',{staticClass:"mb-2",attrs:{"value":_vm.sliderStep,"max":_vm.list.length - 1,"height":"3px"}})],2),_c('div',{staticClass:"d-flex justify-content-around"},_vm._l((_vm.list),function(s){return _c('div',{key:s.key,staticClass:"col text-center slider-text",class:_vm.isEnabled(s.key) ? '' : 'disabled',on:{"click":function($event){_vm.isEnabled(s.key) ? _vm.$emit('select', s.key) : null}}},[_c('Tippy',{ref:`popup${s.key}`,refInFor:true,attrs:{"trigger":"manual","hide-on-click":false,"theme":"light","arrow":"","interactive":"","sticky":"","placement":"bottom"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_vm._v(" "+_vm._s(s.text)+" ")]},proxy:true}],null,true)},[_c('div',[_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":_vm.close}},[_vm._v(" × ")])]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('form.weSavedYourWork'))+"! ")]),_c('div',[_c('button',{staticClass:"btn btn-primary mt-2 btn-sm btn-block",on:{"click":_vm.restore}},[_c('i',{staticClass:"fas fa-check pr-2"}),_vm._v(" "+_vm._s(_vm.$t('form.restore'))+" ")])])])],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }