<template>
  <div>
    <div class="mt-2 font-weight-bold">
      {{ $t('forms.action.CreateBillInfo') }}
    </div>
    <hr>

    <div
      v-if="payerOptions.length === 0"
      class="alert alert-danger"
    >
      <i class="fas fa-exclamation-triangle mr-2" />
      {{ $t('forms.noPayerCreateActionDefined') }}
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('general.title') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <TextOrRule
          v-model="title"
        />
      </div>
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('bill.amount') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <MoneyOrRule
          v-model="amount"
          currency="pln"
        />
      </div>
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('payment.daysToPay') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <div style="padding-right: 42px;">
          <BFormInput
            v-model="daysToPay"
            class="money-font"
          />
        </div>
      </div>
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('payment.accountNumber') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <div style="padding-right: 42px;">
          <BankAccountSelect
            :bank-account-id.sync="bankAccountId"
            :find-default-bank-account="true"
            :validation="bankAccountId ? null : false"
            :static-only="true"
            class="w-100"
            style="max-width: none"
          />
        </div>
      </div>
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('bill.category') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <div style="padding-right: 42px;">
          <CategoryDropdown
            :selected.sync="categoryId"
            :invalid="!categoryId"
          />
          <!-- <BankAccountSelect
            :bank-account-id.sync="bankAccountId"
            :find-default-bank-account="true"
            :validation="bankAccountId ? null : false"
            :static-only="true"
            class="w-100"
            style="max-width: none"
          /> -->
        </div>
      </div>
    </div>
    <div
      v-if="payerOptions.length > 1"
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('transaction.selectClient') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <BFormSelect
          v-model="payerActionId"
          :options="payerOptions"
        />
      </div>
    </div>

    <hr>
    <ActionEnabled
      v-model="actionRule"
    />
  </div>
</template>

<script>
import BankAccountSelect from '@/components/bills/form/BankAccountSelect';
import CategoryDropdown from '@/components/bills/form/CategoryDropdown';
import ActionEnabled from './ActionEnabled';
import MoneyOrRule from './MoneyOrRule';
import TextOrRule from './TextOrRule';

export default {
  props: {
    metadata: Object,
    otherActions: Array,
  },
  data: () => ({
    title: '',
    amount: '',
    daysToPay: '',
    payerActionId: null,
    actionRule: '',
    categoryId: null,
    bankAccountId: null,
  }),
  components: {
    TextOrRule,
    ActionEnabled,
    MoneyOrRule,
    BankAccountSelect,
    CategoryDropdown,
  },
  computed: {
    moneyAmount: {
      get() {
        const n = Number.parseInt(this.amount.replaceAll('"', ''), 10);
        return Number.isNaN(n) ? null : n;
      },
      set(value) {
        this.amount = `"${value}"`;
      },
    },
    payerOptions() {
      return this.otherActions
        .map((x, i) => ({ ...x, id: i }))
        .filter((x) => x.actionType === 'PayerCreate')
        .map((x) => ({
          value: x.id,
          text: `${x.id + 1}. ${this.$t('forms.action.PayerCreate')}`,
        }));
    },
    model() {
      return {
        title: this.title,
        amount: this.amount,
        daysToPay: this.daysToPay,
        currency: 'pln',
        payerActionId: this.payerActionId,
        accountNumber: this.bankAccountId,
        actionRule: this.actionRule,
        categoryId: this.categoryId,
      };
    },
  },
  watch: {
    payerOptions(options) {
      if (options.length === 1) {
        this.payerActionId = options[0].value;
      } else if (options.length === 0) {
        this.payerActionId = null;
      }
    },
    model: {
      handler() {
        this.$emit('update:metadata', this.model);
      },
      deep: true,
    },
  },
  created() {
    this.title = this.metadata.title;
    this.amount = this.metadata.amount;
    this.daysToPay = this.metadata.daysToPay;
    this.payerActionId = this.metadata.payerActionId;
    this.actionRule = this.metadata.actionRule;
    this.categoryId = this.metadata.categoryId;
  },
};
</script>

<style lang="scss" scoped>
.entry-row {
  align-items: center;
  margin-top: 10px;

  i {
    font-size: 1.2rem;
    color: #666;
  }
}

.rule-row {
  padding-left: 5px;

  &:hover {
    background-color: #fafafa;
  }
}
</style>
