<template>
  <div>
    <div class="d-flex justify-content-center">
      <div
        v-if="listing.photoUrl"
        class="user-icon-wrapper"
      >
        <img
          class="photo"
          :src="listing.photoUrl"
        >
      </div>
      <div
        v-else
        class="user-icon-wrapper"
      >
        <div class="user-icon text-center">
          <i
            class="far"
            :class="catalogIcon(catalog.productCategory)"
          />
        </div>
      </div>
    </div>

    <div class="listing-name mt-2">
      {{ listing.name }}
    </div>

    <div class="text-center money-font mt-2">
      {{ formatCurrency(listing.price, listing.currency) }}
    </div>

    <hr>

    <div class="sub-title">
      {{ $t('general.description') }}
    </div>
    <div>
      {{ listing.description }}
    </div>
    <hr>
    <div class="text-right">
      <template v-if="!ordered">
        <button
          v-if="!basketEntry"
          class="btn btn-primary"
          :disabled="!allow"
          @click="addToBasket"
        >
          <i class="fas fa-plus pr-1" />
          {{ $t('shop.addToBasket') }}
        </button>

        <button
          v-else
          class="btn btn-secondary"
          @click="removeFromBasket"
        >
          <i class="fas fa-rotate-left pr-1" />
          {{ $t('shop.removeFromBasket') }}
        </button>
      </template>
      <template v-else>
        <button
          v-if="!basketEntry"
          class="btn btn-secondary"
          :disabled="!allow"
          @click="addToBasket"
        >
          <i class="fas fa-ban pr-1" />
          Odwołaj
        </button>

        <button
          v-else
          class="btn btn-secondary"
          @click="removeFromBasket"
        >
          <i class="fas fa-rotate-left pr-1" />
          Cofnij odwołanie
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  props: {
    catalog: Object,
    listing: Object,
    payerId: String,
    ordered: Object,
  },
  computed: {
    ...mapGetters([
      'catalogIcon',
      'formatCurrency',
      'catalogBasket',
    ]),
    allow() {
      const { cancelTo, orderFrom, orderTo } = this.listing;

      const canCancel = !cancelTo || moment(cancelTo).isAfter(moment());
      const canOrderFrom = !orderFrom || moment(orderFrom, 'YYYY-MM-DD HH:mm').isBefore(moment());
      const canOrderTo = !orderTo || moment(orderTo, 'YYYY-MM-DD HH:mm').isAfter(moment());

      if (this.ordered) {
        return canCancel;
      }

      return canOrderFrom && canOrderTo;
    },
    basketEntry() {
      return this.catalogBasket
        .find((x) => x.payerId === this.payerId && x.offerId === this.listing.id);
    },
  },
  methods: {
    removeFromBasket() {
      this.$store.commit('removeFromBasket', {
        payerId: this.payerId,
        offerId: this.listing.id,
        isReturned: !!this.ordered,
      });

      this.$emit('close');
    },
    addToBasket() {
      this.$store.commit('addBasketCatalog', {
        payerId: this.payerId,
        price: this.listing.price,
        value: this.listing.price,
        currency: this.listing.currency,
        payeeId: this.catalog.payeeId,
        catalogId: this.catalog.id,
        offerName: this.listing.name,
        offerId: this.listing.id,
        catalog: this.catalog,
        listing: this.listing,
        isReturned: !!this.ordered,
        status: 'inBasket',
      });

      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>

.listing-name {
  font-weight: 500;
  text-align: center;
}

.photo {
  max-height: 200px;
  max-width: 200px;
}

.sub-title {
  font-size: 18px;
  font-weight: 500;
}

.user-icon {
  color: #aaa;
  font-size: 20px;
  background-color: #D9E9FF;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue;
}

.user-icon-wrapper {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
