<template>
  <div>
    <div class="mt-2 font-weight-bold">
      {{ $t('forms.action.AddToBillingPlanInfo') }}
    </div>
    <hr>

    <div
      v-if="payerOptions.length === 0"
      class="alert alert-danger"
    >
      {{ $t('forms.noPayerCreateActionDefined') }}
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('billingPlan.plan') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <div style="padding-right: 42px;">
          <BFormSelect
            v-model="billingPlanId"
            :options="billingPlansOptions"
          />
        </div>
      </div>
    </div>
    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('payment.amount') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <MoneyOrRule
          v-model="amount"
          class="w-100"
          :currency="billingPlanCurrency"
        />
      </div>
    </div>

    <div
      v-if="payerOptions.length > 1"
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('transaction.selectClient') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <BFormSelect
          v-model="payerActionId"
          :options="payerOptions"
        />
      </div>
    </div>

    <hr>
    <ActionEnabled
      v-model="actionRule"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import ActionEnabled from './ActionEnabled';
import MoneyOrRule from './MoneyOrRule';

export default {
  props: {
    metadata: Object,
    otherActions: Array,
  },
  data: () => ({
    title: '',
    amount: '',
    daysToPay: '',
    payerActionId: null,
    billingPlanId: null,
    actionRule: '',
    billingPlans: [],
  }),
  components: {
    MoneyOrRule,
    ActionEnabled,
  },
  computed: {
    payerOptions() {
      return this.otherActions
        .map((x, i) => ({ ...x, id: i }))
        .filter((x) => x.actionType === 'PayerCreate')
        .map((x) => ({
          value: x.id,
          text: `${x.id + 1}. ${this.$t('forms.action.PayerCreate')}`,
        }));
    },
    billingPlanCurrency() {
      return this.billingPlans.find((x) => x.id === this.billingPlanId)?.currency || 'pln';
    },
    billingPlansOptions() {
      return this.billingPlans
        .map((x) => ({
          value: x.id,
          text: x.title,
        }))
        .concat([
          {
            value: null,
            text: `${this.$t('forms.selectBillingPlan')}...`,
            disabled: true,
          },
        ]);
    },
    model() {
      return {
        billingPlanId: this.billingPlanId,
        amount: this.amount,
        payerActionId: this.payerActionId,
        actionRule: this.actionRule,
      };
    },
  },
  watch: {
    model() {
      this.$emit('update:metadata', this.model);
    },
    payerOptions(options) {
      if (options.length === 1) {
        this.payerActionId = options[0].value;
      } else if (options.length === 0) {
        this.payerActionId = null;
      }
    },
  },
  methods: {
    ...mapActions(['getBillingPlans']),
    request() {
      this.getBillingPlans()
        .then(({ data }) => {
          this.billingPlans = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.billingPlanId = this.metadata.billingPlanId;
    this.amount = this.metadata.amount;
    this.actionRule = this.metadata.actionRule;
    this.request();
  },
};
</script>

<style lang="scss" scoped>
.entry-row {
  align-items: center;
  margin-top: 10px;

  i {
    font-size: 1.2rem;
    color: #666;
  }
}

.rule-row {
  padding-left: 5px;

  &:hover {
    background-color: #fafafa;
  }
}
</style>
