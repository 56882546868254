<template>
  <div class="d-flex align-items-center">
    <BFormSelect
      v-if="!code"
      :value="value"
      :options="options"
      @input="$emit('input', $event)"
    />
    <BFormTextarea
      v-else
      :value="value"
      :rows="1"
      size="sm"
      class="money-font"
      @input="$emit('input', $event)"
    />
    <div class="ml-2">
      <i
        class="fas hover-icon"
        :class="code ? 'fa-list-dropdown': 'fa-code'"
        @click="code = !code"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layout: Array,
    value: String,
  },
  data: () => ({
    code: false,
  }),
  computed: {
    options() {
      return this.layout
        .filter((x) => x.type !== 'statictext')
        .filter((x) => x.code)
        .map((x) => ({
          value: x.code,
          text: x.title ? `${x.title} (${x.code})` : x.code,
        }));
    },
  },
  created() {
    this.code = this.value && !this.layout.some((x) => x.code === this.value);
  },
};
</script>

<style lang="scss" scoped>
.hover-icon {
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 25px;

  &:hover {
    background-color: #efefef;
    color: $blue;
  }
}
</style>
