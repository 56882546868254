<template>
  <div>
    <div class="mt-2 font-weight-bold">
      {{ $t('forms.action.PayeeNotificationInfo') }}
    </div>
    <hr>

    <div class="d-flex entry-row">
      <i class="fas fa-envelope" />
      <BFormInput
        v-model="email"
        class="ml-3"
        placeholder="email@example.com"
      />
    </div>
    <div class="d-flex entry-row">
      <i class="fas fa-comment-sms" />
      <BFormInput
        v-model="phone"
        class="ml-3"
        placeholder="000 000 000"
      />
    </div>
    <div>
      <hr>
      <ActionEnabled
        v-model="actionRule"
      />
    </div>
  </div>
</template>

<script>
import ActionEnabled from './ActionEnabled';

export default {
  props: {
    metadata: Object,
  },
  data: () => ({
    email: '',
    phone: '',
    message: '',
    actionRule: '',
  }),
  components: {
    ActionEnabled,
  },
  computed: {
    model() {
      return {
        email: this.email,
        phone: this.phone,
        actionRule: this.actionRule,
      };
    },
  },
  watch: {
    model() {
      this.$emit('update:metadata', this.model);
    },
  },
  created() {
    this.email = this.metadata.email;
    this.phone = this.metadata.phone;
  },
};
</script>

<style lang="scss" scoped>
.entry-row {
  align-items: center;
  margin-top: 10px;

  i {
    font-size: 1.2rem;
    color: #666;
  }
}
</style>
