<template>
  <div>
    <div class="mt-2 font-weight-bold">
      {{ $t('forms.action.PayerCreateInfo') }}
    </div>
    <hr>

    <div
      v-for="p in payerFields"
      :key="p.code"
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ p.name }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <FieldOrRule
          v-model="mapping[p.code]"
          :layout="layout"
        />
      </div>
    </div>
    <hr>
    <div
      v-for="p in properties"
      :key="p.code"
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          <i :class="`${getIcon(p.type)} pr-2`" />
          {{ p.name }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <FieldOrRule
          v-model="mapping[p.code]"
          :layout="layout"
        />
      </div>
    </div>
    <hr>
    <div
      v-for="p in userGroups"
      :key="p.code"
      class="row no-gutters align-items-center mt-1 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          <i class="far fa-users pr-2" />
          {{ p.path }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <CheckOrRule
          v-model="mapping[p.code]"
        />
      </div>
    </div>
    <hr>

    <div
      class="row no-gutters align-items-center mt-2 rule-row"
    >
      <div class="col-4">
        <span class="pr-2 mb-0 small text-secondary">
          {{ $t('client.sendInvite') }}
        </span>
      </div>
      <div class="col-8 pr-1">
        <CheckOrRule
          v-model="activate"
        />
      </div>
    </div>
    <hr>
    <ActionEnabled
      v-model="mapping.actionRule"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ActionEnabled from './ActionEnabled';
import CheckOrRule from './CheckOrRule';
import FieldOrRule from './FieldOrRule';

export default {
  props: {
    metadata: Object,
    layout: Array,
  },
  data: () => ({
    activate: false,
    mapping: {},
  }),
  components: {
    FieldOrRule,
    CheckOrRule,
    ActionEnabled,
  },
  computed: {
    ...mapGetters([
      'properties',
      'userGroups',
      'payersPropertyTypes',
    ]),
    model() {
      return {
        actionRule: this.mapping.actionRule,
        firstName: this.mapping.firstName,
        lastName: this.mapping.lastName,
        code: this.mapping.code,
        activate: this.activate,
        properties: this.properties
          .map((x) => ({
            key: x.code,
            value: this.mapping[x.code],
          }))
          .filter((x) => x.value),
        groups: this.userGroups
          .map((x) => ({
            key: x.code,
            value: this.mapping[x.code],
          }))
          .filter((x) => x.value),
      };
    },
    payerFields() {
      return [
        {
          name: this.$t('client.lastName'),
          code: 'lastName',
        },
        {
          name: this.$t('client.firstName'),
          code: 'firstName',
        },
        {
          name: this.$t('client.clientCode'),
          code: 'code',
        }];
    },
  },
  watch: {
    model: {
      deep: true,
      handler(newVal) {
        this.$emit('update:metadata', newVal);
      },
    },
  },
  methods: {
    getIcon(type) {
      return this.payersPropertyTypes.find((x) => x.key === type)?.icon;
    },
  },
  created() {
    const model = this.metadata;

    this.$set(this.mapping, 'firstName', model.firstName);
    this.$set(this.mapping, 'lastName', model.lastName);
    this.$set(this.mapping, 'code', model.code);
    this.$set(this.mapping, 'actionRule', model.actionRule);
    this.activate = model.activate;

    this.properties.forEach((x) => {
      const prop = (model.properties || []).find((y) => y.key === x.code);
      if (prop && prop.value) {
        this.$set(this.mapping, prop.key, prop.value);
      }
    });

    this.userGroups.forEach((x) => {
      const g = (model.groups || []).find((y) => y.key === x.code);
      if (g && g.value) {
        this.$set(this.mapping, g.key, g.value);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.rule-row {
  padding-left: 5px;

  &:hover {
    background-color: #fafafa;
  }
}
</style>
