<template>
  <div
    class="h-100 d-flex flex-column"
    style="overflow: hidden"
  >
    <div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="page-header">
          <h1>
            {{ $t('settings.forms.updateForm') }}
          </h1>
        </div>
        <div>
          <button
            type="button"
            aria-label="Close"
            class="close"
            @click="cancel"
          >
            ×
          </button>
        </div>
      </div>
      <hr class="mt-1">

      <div class="row align-items-center">
        <StepSelect
          class="col-9"
          :page="page"
          :enabled="filledPages"
          :list="steps"
          @select="page = $event"
        />
        <div class="col-3 text-right">
          <button
            v-if="page == 'layout'"
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled"
            @click="next"
          >
            {{ $t('general.next') }}
            <i class="fas fa-arrow-right px-2" />
          </button>
          <button
            v-else
            class="btn btn-primary btn-lg next-btn mr-1"
            :disabled="disabled || pending"
            @click="save"
          >
            {{ $t('general.save') }}
            <i class="fas fa-check px-2" />
          </button>
        </div>
      </div>

      <hr class="mb-2">

      <div v-if="!ready">
        <Loader />
      </div>
      <LayoutEditor
        v-else-if="page === 'layout'"
        class="row"
        :value.sync="layout"
      />
      <div v-else>
        <div class="row">
          <div
            class="col-4 actions-column"
          >
            <div
              v-for="(a, i) in actions"
              :key="i"
              class="action-item d-flex align-items-center"
              :class="{ selected: i === selectedActionId }"
            >
              <div class="font-weight-bold flex-grow-1">
                {{ i+1 }}.
                {{ $t(`forms.action.${a.actionType}`) }}
              </div>
              <div
                v-if="i === selectedActionId"
                class="field-edit selected"
              >
                <i class="fas fa-arrow-right" />
              </div>
              <div
                v-else
                class="field-edit"
                @click="selectedActionId = i"
              >
                <i class="fas fa-pen" />
              </div>
            </div>
            <div class="text-center mt-4 d-flex align-items-center">
              <BFormSelect
                v-model="newActionType"
                :options="actionTypes"
                class="flex-grow-1"
              />
              <button
                class="btn btn-primary field-add ml-2"
                :disabled="!newActionType"
                @click="addAction"
              >
                <i class="fas fa-add" />
              </button>
            </div>
          </div>
          <div
            v-if="selectedAction"
            class="col-8 scroll-form"
          >
            <PayerAddAction
              v-if="selectedAction.actionType === 'PayerCreate'"
              :key="selectedActionId"
              :metadata.sync="selectedAction.metadata"
              :layout="layout"
            />
            <BillCreateAction
              v-else-if="selectedAction.actionType === 'CreateBill'"
              :key="selectedActionId"
              :metadata.sync="selectedAction.metadata"
              :layout="layout"
              :other-actions="actions"
            />
            <AddToBillingPlanAction
              v-else-if="selectedAction.actionType === 'AddToBillingPlan'"
              :key="selectedActionId"
              :metadata.sync="selectedAction.metadata"
              :layout="layout"
              :other-actions="actions"
            />
            <PayeeNotificationAction
              v-else-if="selectedAction.actionType === 'PayeeNotification'"
              :key="selectedActionId"
              :metadata.sync="selectedAction.metadata"
              :layout="layout"
            />
            <hr>
            <div>
              <button
                class="btn btn-danger"
                @click="removeAction"
              >
                {{ $t('forms.remove') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepSelect from '@/components/bills/form/StepSelect';
import LayoutEditor from '@/components/forms/LayoutEditor';
import { mapActions, mapGetters } from 'vuex';
import AddToBillingPlanAction from './actions/AddToBillingPlanAction';
import BillCreateAction from './actions/BillCreateAction';
import PayeeNotificationAction from './actions/PayeeNotificationAction';
import PayerAddAction from './actions/PayerAddAction';

export default {
  props: {
    id: String,
  },
  data: () => ({
    disabled: false,
    pending: false,
    layout: [],
    actions: [],
    selectedActionId: null,
    page: 'layout',
    ready: false,
    newActionType: '',
  }),
  components: {
    StepSelect,
    LayoutEditor,
    BillCreateAction,
    PayeeNotificationAction,
    AddToBillingPlanAction,
    PayerAddAction,
  },
  computed: {
    ...mapGetters([
      'payeeId',
    ]),
    selectedAction() {
      return this.actions[this.selectedActionId];
    },
    actionTypes() {
      return [
        {
          value: '',
          text: this.$t('forms.action.select'),
        },
        {
          value: 'PayerCreate',
          hide: this.actions.some((x) => x.actionType === 'PayerCreate'),
          text: this.$t('forms.action.PayerCreate'),
        },
        {
          value: 'PayeeNotification',
          text: this.$t('forms.action.PayeeNotification'),
        },
        {
          value: 'CreateBill',
          text: this.$t('forms.action.CreateBill'),
        },
        {
          value: 'AddToBillingPlan',
          text: this.$t('forms.action.AddToBillingPlan'),
        },
      ];
    },
    steps() {
      return [
        {
          key: 'layout',
          text: this.$t('forms.forms.layout'),
        },
        {
          key: 'actions',
          text: this.$t('forms.forms.actions'),
        },
      ];
    },
    filledPages() {
      return this.steps.map((x) => x.key);
    },
  },
  methods: {
    ...mapActions([
      'updateFormDefinitionDetails',
      'getFormDefinition',
    ]),
    removeAction() {
      const id = this.selectedActionId;
      this.selectedActionId = null;
      this.actions.splice(id, 1);
    },
    addAction() {
      this.actions.push({
        actionType: this.newActionType,
        metadata: {},
      });

      this.selectedActionId = this.actions.length - 1;
      this.newActionType = this.actionTypes[0].value;
    },
    save() {
      this.pending = true;
      this.updateFormDefinitionDetails({
        params: {
          formId: this.id,
        },
        data: {
          layout: JSON.stringify(this.layout),
          actions: this.actions.map((x, i) => ({
            actionId: i,
            actionType: x.actionType,
            metadata: JSON.stringify(x.metadata),
          })),
        },
      })
        .then(() => {
          this.pending = false;
          this.$router.push(`/payee/${this.payeeId}/settings/forms`);
        })
        .catch(() => {
          this.pending = false;
        });
    },

    next() {
      this.page = 'actions';
    },
    cancel() {
      this.$router.back();
    },
    request() {
      this.getFormDefinition({
        params: {
          formId: this.id,
        },
      })
        .then(({ data }) => {
          this.layout = JSON.parse(data.layout || '[]');
          this.actions = (data.actions || []).map((x) => ({
            ...x,
            metadata: JSON.parse(x.metadata || '{}'),
          }));
          this.ready = true;
        });
    },
  },
  created() {
    this.$emit('page', 'form');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  @import './actions/style.scss';

  .close {
    font-size: 40px;
  }

  .field-add {
    max-width: 200px;
    margin: auto;
  }

  .bill-button {
    width: 150px !important;
  }

  .edit-details {
    font-size: 14px;
  }

  .actions-column {
    border-right: 1px solid #eee;
    height: calc(100vh - 220px);
  }

  .field-edit {
    padding-left: 5px;
    align-self: center;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 15px;
    background-color: #f6f6f6;
    border-radius: 50%;
    width: 35px;
    min-width: 35px;
    height: 35px;
    text-align: center;

    &:hover {
      background-color: #ddd;
    }

    &.selected {
      background-color: #ddd;
    }
  }

  .action-item {
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 5px;
    background-color: #fafafa;
    border-left: 2px solid transparent;

    &.selected {
      background-color: rgba($blue, 0.1);
      border-left: 2px solid $blue;
    }
  }

  .scroll-form {
    height: calc(100vh - 220px);
    padding-bottom: 40px;
    overflow-y: auto;
  }
</style>
