<template>
  <div class="d-flex align-items-center">
    <div class="mr-3 pl-3 small col-4 text-secondary">
      <i class="fa-solid fa-code mr-1" />
      {{ $t('forms.enableActionRule') }}:
    </div>
    <div
      v-if="enableActionRule"
      class="flex-grow-1"
    >
      <BFormTextarea
        :value="value"
        size="sm"
        rows="1"
        class="money-font"
        @input="$emit('input', $event)"
      />
    </div>
    <div
      v-else
      class="flex-grow-1"
    />
    <div class="ml-2 pr-1">
      <i
        class="fas hover-icon"
        :class="enableActionRule ? 'fa-times': 'fa-code'"
        @click="enableActionRule = !enableActionRule; $emit('input', '')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  },
  data: () => ({
    enableActionRule: false,
  }),
  created() {
    if (this.value) {
      this.enableActionRule = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
