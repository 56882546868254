<template>
  <div class="d-flex align-items-center">
    <MoneyInput
      v-if="!code"
      v-model="moneyAmount"
      class="w-100"
      :currency="currency"
      align-left
    />
    <BFormTextarea
      v-else
      :value="value"
      :rows="1"
      size="sm"
      class="money-font"
      @input="$emit('input', $event)"
    />
    <div class="ml-2">
      <i
        class="fas hover-icon"
        :class="code ? 'fa-list-dropdown': 'fa-code'"
        @click="code = !code"
      />
    </div>
  </div>
</template>

<script>
import MoneyInput from '@/components/utils/MoneyInput';

export default {
  props: {
    value: String,
    currency: String,
  },
  data: () => ({
    code: false,
  }),
  components: {
    MoneyInput,
  },
  computed: {
    moneyAmount: {
      get() {
        const n = Number.parseInt(this.value, 10);
        return Number.isNaN(n) ? null : n;
      },
      set(value) {
        this.$emit('input', value.toString());
      },
    },
  },
  created() {
    const intVal = Number.parseInt((this.value || ''), 10);

    if (intVal.toString() === this.value) {
      this.code = false;
    } else {
      this.code = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
