<template>
  <div>
    <div v-if="isAdmin && (!gateway || gateway.metadata)">
      <div class="mb-2 small">
        Parametry BlueMedia
      </div>

      <BFormInput
        v-model="meta.serviceId"
        placeholder="ServiceId"
        :disabled="!!gateway"
        size="sm"
        class="my-2"
      />
      <BFormInput
        v-model="meta.key"
        placeholder="Key"
        :disabled="!!gateway"
        size="sm"
        class="my-2"
      />
      <BFormInput
        v-model="meta.commissionValue"
        placeholder="Commission (%)"
        :disabled="!!gateway"
        size="sm"
        class="my-2"
      />
      <div class="mt-2 small">
        {{ $t('settings.gateway.accountNumberProvidedInForm') }}:
      </div>
      <BankAccountSelect
        :bank-account-id.sync="accountNumber"
        find-default-bank-account
        :disabled="!!gateway && gateway.accountNumber"
        static-only
        small
        style="max-width: 100%;"
        :validation="!gateway || gateway.accountNumber ? null : (accountNumber != null)"
      />
    </div>

    <hr class="mt-1">
    <div
      class="my-2"
    >
      <BFormCheckbox
        v-model="enabled"
        switch
        class="white-switch white-switch-sm"
        :disabled="pending"
      >
        {{ $t('settings.gateway.enabled') }}
      </BFormCheckbox>
    </div>
    <div class="my-2">
      <BFormCheckbox
        v-model="isDefault"
        switch
        class="white-switch white-switch-sm"
        :disabled="pending"
      >
        {{ $t('settings.gateway.isDefault') }}

        <i
          v-tippy
          :content="$t('settings.info.defaultGateway')"
          class="fa-solid fa-circle-info ml-2"
        />
      </BFormCheckbox>
    </div>
    <div class="my-2">
      <BFormCheckbox
        v-model="payeePaysCommission"
        switch
        class="white-switch white-switch-sm"
        :disabled="pending"
      >
        {{ $t('settings.commission.companyPays') }}

        <i
          v-tippy
          :content="$t('settings.info.companyPaysCommission')"
          class="fa-solid fa-circle-info ml-2"
        />
      </BFormCheckbox>
    </div>
    <hr class="mb-2">
    <div class="pl-1">
      <div class="mb-2 small">
        {{ $t('settings.billsCategories') }}:
      </div>
      <CategoriesMultiselect
        :selected.sync="enabledCategories"
      />
    </div>

    <div
      v-if="error"
      class="text-danger p-2 small"
    >
      {{ categoriesOverlap ? $t('settings.gateway.categoriesOverlap') : $t('error.type.Unknown') }}
    </div>
  </div>
</template>

<script>
import BankAccountSelect from '@/components/bills/form/BankAccountSelect';
import { mapActions, mapGetters } from 'vuex';
import CategoriesMultiselect from '../CategoriesMultiselect';

export default {
  props: {
    gateway: Object,
  },
  data: () => ({
    enabled: false,
    isDefault: false,
    error: false,
    categoriesOverlap: false,
    pending: false,
    payeePaysCommission: false,
    enabledCategories: [],
    accountNumber: null,
    meta: {
      key: '',
      serviceId: '',
      commissionValue: '0.012',
      baseUrl: 'https://pay.autopay.eu/payment',
    },
  }),
  components: {
    BankAccountSelect,
    CategoriesMultiselect,
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {
    ...mapActions([
      'addPaymentGateway',
      'updatePaymentGateway',
      'removePaymentGateway',
    ]),
    remove() {
      if (!this.isAdmin) return;
      this.$emit('update:pending', true);
      this.removePaymentGateway({
        params: {
          gatewayId: this.gateway.id,
        },
      })
        .then(() => {
          this.$emit('removed');
        })
        .finally(() => {
          this.$emit('update:pending', false);
        });
    },
    submit() {
      if (this.gateway) {
        this.update();
      } else if (this.isAdmin) {
        this.add();
      }
    },
    update() {
      this.categoriesOverlap = false;
      this.error = false;
      this.$emit('update:pending', true);
      this.updatePaymentGateway({
        params: {
          gatewayId: this.gateway.id,
        },
        data: {
          enabled: this.enabled,
          isDefault: this.isDefault,
          payeePaysCommission: this.payeePaysCommission,
          categoryIds: this.enabledCategories,
          accountNumber: this.accountNumber,
        },
      })
        .then(({ data }) => {
          this.$emit('updated', data);
        })
        .catch(({ response: { data } }) => {
          this.error = true;
          if (data && data.type === 'OverlappingCategoriesException') {
            this.categoriesOverlap = true;
          }
        })
        .finally(() => {
          this.$emit('update:pending', false);
        });
    },
    add() {
      this.categoriesOverlap = false;
      this.error = false;
      this.$emit('update:pending', true);
      this.addPaymentGateway({
        data: {
          enabled: this.enabled,
          isDefault: this.isDefault,
          type: 'BlueMedia',
          commissionType: 'Percentage',
          commissionValue: this.meta.commissionValue,
          payeePaysCommission: this.payeePaysCommission,
          categoryIds: this.enabledCategories,
          accountNumber: this.accountNumber,
          metadata: JSON.stringify({
            Key: this.meta.key,
            ServiceId: this.meta.serviceId,
            BaseUrl: this.meta.baseUrl,
          }),
        },
      })
        .then(({ data }) => {
          this.$emit('added', data);
        })
        .catch(({ response: { data } }) => {
          this.error = true;
          if (data && data.type === 'OverlappingCategoriesException') {
            this.categoriesOverlap = true;
          }
        })
        .finally(() => {
          this.$emit('update:pending', false);
        });
    },
  },
  created() {
    if (this.gateway) {
      this.enabled = this.gateway.enabled;
      this.isDefault = this.gateway.isDefault;
      this.meta.commissionValue = this.gateway.commissionValue;
      this.payeePaysCommission = this.gateway.payeePaysCommission;
      this.enabledCategories = this.gateway.categoryIds;

      if (this.isAdmin && this.gateway.metadata) {
        const meta = JSON.parse(this.gateway.metadata);
        this.meta.serviceId = meta.ServiceId;
        this.meta.key = meta.Key;
        this.meta.baseUrl = meta.BaseUrl;
      }
    }
  },
};
</script>
