<template>
  <ColumnEdit
    v-if="!pending"
    class="page-content px-3 pt-2"
    :edit="!!selected"
    :modal="detailsInModal"
  >
    <div
      v-if="waitingSchedule.length > 0"
      class="font-weight-bold mb-3"
    >
      {{ $t('notify.pendingNotifications') }}:
    </div>
    <div
      v-for="s in waitingSchedule"
      :key="s.scheduleId"
    >
      <NotificationScheduleRow
        v-bind="s"
        class="mt-1"
        :selected="isSelected(s)"
        @click.native="toggle(s)"
      />
    </div>

    <hr>
    <div class="font-weight-bold mb-3">
      {{ $t('notify.sentNotifications') }}:
    </div>

    <div
      v-for="s in completedSchedule"
      :key="s.scheduleId"
    >
      <NotificationScheduleRow
        v-bind="s"
        class="mt-1"
        :selected="isSelected(s)"
        @click.native="toggle(s)"
      />
    </div>

    <template #edit>
      <Transition
        name="client-details"
        mode="out-in"
      >
        <div
          v-if="selected && detailsReady"
          class="page-cart position-relative ml-2 h-100"
          style="overflow-y: scroll"
        >
          <CloseButton
            style="position: absolute; right: 3px; top: 3px; z-index: 100"
            @click.native.stop="selected = false; detailsReady = false"
          />

          <NotificationDetails
            v-bind="selected"
            @close="selected = null"
            @refresh="selected = null; request()"
          />
        </div>
      </Transition>
    </template>
  </ColumnEdit>
  <div v-else>
    <Loader />
  </div>
</template>

<script>
import ColumnEdit from '@/components/layout/ColumnEdit';
import NotificationDetails from '@/components/notifications/NotificationDetails';
import NotificationScheduleRow from '@/components/notifications/NotificationScheduleRow';
import moment from 'moment';
import { mapActions } from 'vuex';

export default {
  data: () => ({
    notificationSchedule: [],
    pending: false,
    selected: null,
    detailsReady: false,
    detailsInModal: false,
  }),
  components: {
    ColumnEdit,
    NotificationDetails,
    NotificationScheduleRow,
  },
  computed: {
    waitingSchedule() {
      return this.notificationSchedule
        .filter((x) => !x.delivered && !x.skipped)
        .sort((a, b) => moment(a.timestamp).unix() - moment(b.timestamp).unix());
    },
    completedSchedule() {
      return this.notificationSchedule
        .filter((x) => x.delivered || x.skipped)
        .sort((a, b) => moment(b.delivered || b.skipped).unix() - moment(a.delivered || a.skipped).unix());
    },
  },
  methods: {
    ...mapActions([
      'getNotificationsPlan',
    ]),
    isSelected({ scheduleId }) {
      return this.selected && this.selected.scheduleId === scheduleId;
    },
    toggle(s) {
      if (this.isSelected(s)) {
        this.selected = null;
        this.detailsReady = false;
      } else {
        this.selected = s;
        this.timeout = setTimeout(() => {
          this.detailsReady = true;
        }, 200);
      }
    },
    request() {
      this.pending = true;
      this.getNotificationsPlan({
        params: {
          query: {
            from: moment().utc().subtract(7, 'day').startOf('day')
              .format(),
            to: moment().utc().add(60, 'day').format(),
          },
        },
      })
        .then(({ data }) => {
          this.notificationSchedule = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.$emit('page', 'notifications');
    this.request();
  },
};
</script>

<style lang="scss" scoped>
  .client-details-enter-active,
  .client-details-leave-active {
    transition: opacity 200ms, transform 200ms;
  }

  .client-details-enter, .client-details-leave-to {
    opacity: 0;
    transform: translateX(-10px);
  }

  .page-content {
    height: calc(100vh - #{$navbarHeight} - 240px);
    overflow-x: hidden;
  }
</style>
