<template>
  <div
    class="notification-row"
    :class="{
      selected,
      completed: delivered || skipped,
    }"
  >
    <div class="d-flex align-items-center">
      <div>
        <i
          :class="icon"
          class="light-text pr-3"
        />
      </div>
      <div class="flex-grow-1">
        <div
          class="font-weight-bold"
          style="line-height: 1.2"
        >
          {{ $t(`notify.type.${subjectType}`) }}
        </div>
        <div class="light-text small text-truncate">
          {{ title }}
        </div>
      </div>
      <div v-if="skipped">
        <span class="badge badge-secondary mr-3 px-2 mb-1">
          <i class="fas fa-ban pr-1" />
          {{ $t("messages.skipped") }}
        </span>
      </div>
      <div style="width: 150px">
        <div v-if="delivered">
          <div class="small text-secondary">
            {{ $t("notify.delivered") }}:
          </div>
          <div class="notify-date font-weight-bold">
            {{ formatDate(delivered) }}
          </div>
        </div>
        <div v-else-if="skipped">
          <div class="small text-secondary">
            {{ $t("notify.skippedDelivery") }}:
          </div>
          <div class="notify-date font-weight-bold">
            {{ formatDate(skipped) }}
          </div>
        </div>
        <div v-else>
          <div class="small text-secondary">
            {{ $t("notify.plannedDelivery") }}:
          </div>
          <div class="notify-date font-weight-bold">
            {{ formatDate(timestamp) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    subjectType: String,
    timestamp: String,
    status: String,
    title: String,
    selected: Boolean,
    delivered: String,
    skipped: String,
    id: Number,
    notifications: Array,
  },
  computed: {
    icon() {
      switch (this.subjectType) {
        case 'MessageSentNotification':
          return 'fas fa-comments';
        case 'BillCreatedNotification':
          return 'fas fa-dollar-sign';
        case 'BillCancelledNotification':
          return 'fas fa-trash-alt';
        case 'BillDueDateExpiredNotification':
          return 'fas fa-clock';
        case 'BillsReminderNotification':
          return 'fas fa-history';
        case 'BillingScopeSummaryNotification':
          return 'far fa-file-invoice';
        case 'DocumentDeliveryNotification':
          return 'far fa-file';
        default:
          return 'fas fa-question';
      }
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('D MMMM YYYY HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-row {
  border: 2px solid #eee;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;

  .light-text {
    color: #888;
    max-width: 500px;
  }

  &:hover {
    background-color: #f8f8f8;
  }

  &.completed {
    background-color: rgba($gray, 0.2);
  }

  &.selected {
    background-color: rgba(0, 112, 255, 0.5);
    .light-text {
      color: rgb(70, 107, 156);
    }
  }
}

.notification-row > div {
  height: 39px;
}

.notify-date {
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .notification-row > div {
    height: 60px;
  }
}
</style>
