<template>
  <div class="h-100">
    <div class="page-title-wrapper">
      <div class="page-title active">
        {{ $t('menu.payments') }}
      </div>
      <div
        class="page-title ml-4"
        @click="showBankStatements"
      >
        {{ $t('bankStatements.title') }}
      </div>
    </div>

    <div
      v-if="!focus"
      class="d-flex align-items-center justify-content-between"
    >
      <div class="d-flex flex-wrap">
        <YearDropdown
          :selected.sync="year"
          class="mr-3 mb-1"
        />
        <PayersFilterDropdown
          class="mr-3 mb-1"
          @selected="selectedPayers = $event"
        />
        <PaymentStatusDropdown
          :selected.sync="paymentStatus"
          class="mr-3 mb-1"
        />
        <SearchBox
          v-model="search"
          lazy
          class="mr-3 mb-1"
        />
      </div>
      <div>
        <RouterLink
          v-show="!focus"
          :to="`/payee/${payeeId}/payments/form`"
        >
          <button
            class="btn btn-sm btn-primary px-3"
            data-test="toggle-add-bill"
            style="border-radius: 15px"
          >
            <i class="fas fa-add px-1" />
            {{ $t('payment.add') }}
          </button>
        </RouterLink>
      </div>
    </div>
    <hr
      v-if="!focus"
      class="mt-2 mb-3"
    >
    <ColumnEdit
      :focused="focus"
      :edit="!!edited || !!auditScope"
      :modal="detailsInModal"
    >
      <div
        ref="wrapper"
        class="page-content"
        :class="{ focus }"
      >
        <BillingScopeList
          :billing-scopes="billingScopesWithPayments"
          :additional-scopes="future"
          @more="showFuture"
        >
          <template #title="{ scope }">
            <div class="flex-grow-1 d-flex justify-content-end">
              <!-- <div
                v-tippy
                class="light-button scope-lock mr-2"
                :content="$t('bill.changeHistory')"
                :class="{
                  selected: auditScope && auditScope.id === scope.id
                }"
                @click="toggleAuditScope(scope)"
              >
                <i class="fa-solid fa-clock-rotate-left" />
              </div> -->
              <div
                v-tippy
                class="light-button scope-lock"
                :content="$t('general.export')"
                @click="!pendingExport[scope.from] ? requestExportPayments(scope) : undefined"
              >
                <i
                  v-if="!pendingExport[scope.from]"
                  class="fa-solid fa-download"
                />
                <Loader
                  v-else
                  size="15px"
                  style="margin-top: 5px;"
                />
              </div>
            </div>
          </template>
          <template #default="{ scope }">
            <div
              v-if="scope.payments.length === 0"
              class="ml-2 small text-secondary font-weight-bold text-uppercase mt-2"
            >
              {{ $t('payment.none') }}
            </div>

            <div class="payments-list">
              <div
                v-for="payment in scope.payments"
                :key="payment.id"
                class="py-2 payment-row d-flex align-items-center"
                :class="{ selected: edited && edited.id === payment.id }"
                @click="toggle(payment)"
              >
                <div class="type px-2">
                  <i :class="payment.typeDetails ? payment.typeDetails.icon : 'fas fa-question'" />
                  <span class="pl-1 small">
                    {{ payment.typeDetails.name }}
                  </span>
                </div>
                <div class="text-center date">
                  {{ formatDate(payment.timestamp) }}
                </div>
                <div
                  v-if="!edited"
                  class="flex-grow-1 title"
                >
                  {{ payment.title }}
                </div>
                <div
                  v-else
                  class="flex-grow-1"
                />

                <div class="is-settled px-2 text-truncate">
                  <span
                    v-if="payment.value == payment.settled"
                    class="d-flex align-items-center"
                  >
                    <i class="fas fa-clipboard-check text-secondary pr-2" />

                    <div class="payment-payers">
                      <span
                        v-for="(payer, i) in payment.payers"
                        :key="payer.id"
                      ><span v-if="i > 0">, </span>{{ payer.name }}</span>
                    </div>
                  </span>
                  <span
                    v-else
                    class="text-danger d-flex align-items-center"
                  >
                    <i class="fa-solid fa-clipboard-question pr-2" />
                    <span class="payment-payers">
                      {{ $t('payment.unsettled') }}
                    </span>
                  </span>
                </div>
                <div class="money-font value">
                  {{ formatCurrency(payment.value, payment.currency) }}
                </div>
              </div>
            </div>
          </template>
        </BillingScopeList>
        <InfinityScrollCursor
          ref="cursor"
          @request="request"
        />
        <div
          class="text-center"
          style="height: 100px"
        >
          <Loader
            v-if="pending"
          />
        </div>
      </div>
      <template #edit>
        <div
          v-if="auditScope"
          class="p-2 page-content"
        >
          <CloseButton
            v-if="!focus"
            style="position: absolute; right: 4px; top: 4px; z-index: 100"
            @click.native.stop="auditScope = null"
          />
          <PaymentsAudit
            class="page-cart px-4 h-100 pt-4"
            :scope="auditScope"
            @close="edited = null; focus = false"
          />
        </div>
        <div
          v-else-if="edited"
          class="p-2 page-content"
          :class="{ focus }"
        >
          <CloseButton
            v-if="!focus"
            style="position: absolute; right: 4px; top: 4px; z-index: 100"
            @click.native.stop="edited = null"
          />
          <PaymentDetails
            class="page-cart px-4 pt-4"
            :payment-id="edited.id"
            :payment="edited"
            with-transfer
            @update="update"
            @focus="focus = $event"
            @removed="removed"
            @close="edited = null; focus = false"
          />
        </div>
      </template>
    </ColumnEdit>
  </div>
</template>

<script>
import InfinityScrollCursor from '@/components/InfinityScrollCursor';
import YearDropdown from '@/components/YearDropdown';
import BillingScopeList from '@/components/billingScopes/BillingScopeList';
import ColumnEdit from '@/components/layout/ColumnEdit';
import PayersFilterDropdown from '@/components/payers/PayersFilterDropdown';
import PaymentDetails from '@/components/payments/PaymentDetails';
import PaymentStatusDropdown from '@/components/payments/PaymentStatusDropdown';
import PaymentsAudit from '@/components/payments/audit/PaymentsAudit';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

const takeMonths = 1;

export default {
  data: () => ({
    selectedPayers: [],
    add: false,
    payments: [],
    billingScopes: [],
    edited: null,
    detailsInModal: false,
    focus: false,
    pending: false,
    search: '',
    auditScope: null,
    future: 0,
    pendingExport: {},
    from: moment().startOf('month').subtract(takeMonths - 1, 'months'),
    to: moment().startOf('month').add(1, 'months'),
    year: moment().format('YYYY'),
    haveAllData: false,
    destroyed: false,
    paymentStatus: 'Unspecified',
  }),
  components: {
    PayersFilterDropdown,
    InfinityScrollCursor,
    PaymentStatusDropdown,
    BillingScopeList,
    PaymentDetails,
    YearDropdown,
    ColumnEdit,
    PaymentsAudit,
  },
  computed: {
    ...mapGetters([
      'formatCurrency',
      'payeeId',
      'paymentTypes',
      'donations',
    ]),
    paymentsWithTypes() {
      return this.payments.map((x) => ({
        ...x,
        typeDetails: this.paymentTypes.find((y) => y.key === x.type),
      }));
    },
    billingScopesWithPayments() {
      return this.billingScopes.map((x) => ({
        ...x,
        payments: this.paymentsWithTypes.filter((p) => p.billingScopeId === x.id),
      }));
    },
  },
  watch: {
    selectedPayers() {
      this.resetFrom(moment().startOf('month'));
    },
    search() {
      this.resetFrom(moment().startOf('month'));
    },
    paymentStatus() {
      this.resetFrom(moment().startOf('month'));
    },
    year(year) {
      this.resetFrom(moment(`12-${year}`, 'MM-YYYY').startOf('month'));
    },
  },
  methods: {
    ...mapActions([
      'getPayments',
      'getDonations',
      'exportPayments',
    ]),
    requestExportPayments(scope) {
      this.$set(this.pendingExport, scope.from, true);
      this.exportPayments({
        params: {
          query: {
            from: scope.from,
            to: scope.to,
            payerIds: this.selectedPayers.map((x) => x.id),
            search: this.search || undefined,
            status: this.paymentStatus,
          },
        },
      })
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          const fileName = `${this.$t('menu.payments')}_${scope.from}_${scope.to}`;
          link.setAttribute('download', `${fileName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .finally(() => {
          this.$set(this.pendingExport, scope.from, false);
        });
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    showFuture() {
      this.future += 6;
      this.resetFrom(moment().startOf('month'));
    },
    removed(id) {
      this.payments = this.payments.filter((x) => x.id !== id);
    },
    showBankStatements() {
      this.$router.push('./payments/bank-statements');
    },
    update(p) {
      const payment = this.payments.find((x) => x.id === p.id);
      payment.settled = p.settled;
    },
    toggle(e) {
      if (this.edited != null && this.edited?.id === e?.id) {
        this.edited = null;
      } else {
        this.edited = e;
        this.auditScope = null;
      }
    },
    toggleAuditScope(e) {
      if (this.auditScope != null && this.auditScope?.id === e?.id) {
        this.auditScope = null;
      } else {
        this.auditScope = e;
        this.edited = null;
      }
    },
    resetFrom(date) {
      this.haveAllData = false;
      this.payments = [];
      this.billingScopes = [];
      this.from = moment(date).subtract(takeMonths - 1, 'months');
      this.to = moment(date).add(this.future + 1, 'months');
      this.request();
    },
    request() {
      if (this.haveAllData || this.pending || this.destroyed) return;
      this.pending = true;
      this.getPayments({
        params: {
          query: {
            from: this.from.format('YYYY-MM-DD'),
            to: this.to.format('YYYY-MM-DD'),
            payerIds: this.selectedPayers.map((x) => x.id),
            search: this.search || undefined,
            status: this.paymentStatus,
          },
        },
      })
        .then(({ data }) => {
          const oldest = moment(data.oldestBillingScope, 'YYYY-MM-DD');

          const uniquePayments = data.payments.filter((x) => !this.payments.some((y) => y.id === x.id));
          const uniqueScopes = data.scopes.filter((x) => !this.billingScopes.some((y) => y.id === x.id));

          this.payments = [...this.payments, ...uniquePayments]
            .sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix());

          this.billingScopes = [...this.billingScopes, ...uniqueScopes];

          if (oldest.isBefore(this.from)) {
            this.from = this.from.subtract(takeMonths, 'month');
            this.to = this.to.subtract(takeMonths, 'month');
            setTimeout(() => {
              this.pending = false;
              if (!this.$refs.cursor || !this.$refs.cursor.isBottom()) {
                this.request();
              }
            }, 200);
          } else {
            this.pending = false;
            this.haveAllBills = true;
          }
        });
    },
  },
  created() {
    this.request();

    if (!this.donations) {
      this.getDonations();
    }

    this.$emit('page', 'payments');
  },
  destroyed() {
    this.destroyed = true;
  },
};
</script>

<style lang="scss" scoped>

  .payment-row {
    background-color: #fbfbfb;
    margin-top: 6px;
    padding: 4px 15px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;

    .type {
      width: 120px;
      display: flex;
      line-height: 24px;

      i {
        font-size: 16px;
        line-height: 24px;
        padding-right: 2px;
        width: 20px;
        text-align: center;
      }
    }

    .value {
      width: 150px;
      min-width: 150px;
      text-align: right;
      border-left: 2px solid #eee;
    }

    .is-settled {
      border-left: 2px solid #eee;
      min-width: 250px;
      max-width: 250px;

      i {
        line-height: 1;
        font-size: 20px;
        padding-right: 5px;
      }

      .payment-payers {
        font-size: 12px;
      }
    }

    .title {
      border-left: 2px solid #eee;
      padding-left: 10px;
      font-size: 14px;
      line-height: 24px;
    }

    .date {
      width: 150px;
      min-width: 150px;
      padding-left: 10px;
      padding-right: 10px;
      border-left: 2px solid #eee;
    }

    &:hover {
      background-color: rgba(100, 100, 100, 0.1);
    }

    &.selected {
      background-color: rgba($blue, 0.2);
    }
  }

  .page-cart {
    min-height: 100%;
  }

  .page-content {
    height: calc(100vh - #{$navbarHeight} - 147px);
    overflow-y: scroll;
    position: relative;

    &.focus {
      height: calc(100vh - #{$navbarHeight});
    }
  }

  .form-header {
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 15px;
  }

  .scope-lock {
    &.selected {
      background-color: #0070ff33;
    }
  }
</style>
