<template>
  <div v-if="!edit && !audit">
    <div
      class="d-flex justify-content-between align-items-center pb-3"
    >
      <PayerHeader
        :type="details?.type || type"
        :name="details?.name || name"
        :code="details?.code || code"
        :number="details?.number || number"
        class="px-2"
      />
      <div
        v-if="!focus"
        class="d-flex"
      >
        <div class="pl-2">
          <i
            v-if="details"
            v-tippy
            class="fas fa-clock-rotate-left secondary-icon smaller-icon"
            :content="$t('bill.changeHistory')"
            @click="audit = true"
          />
        </div>
        <div class="pl-2">
          <i
            v-if="details"
            v-tippy
            class="fas fa-pen secondary-icon smaller-icon"
            :content="$t('general.edit')"
            @click="edit = true"
          />
        </div>
      </div>
    </div>

    <PayerDataSummary
      v-if="details && !focus"
      v-bind="details"
    />

    <template v-if="!focus">
      <div
        class="d-flex justify-content-between align-items-center"
        :class="moreBalance ? 'mt-2' : 'mt-4'"
      >
        <h5 class="payer-section mb-0">
          {{ $t('payers.balance') }}
        </h5>
      </div>
      <hr class="mt-2">
      <PayerBalance
        :balance="details ? details.balance : balance"
        :more="moreBalance"
      />
    </template>

    <template v-if="!moreBalance && !moreBills && !hideOverpayments">
      <div
        v-if="!moreBalance"
        class="d-flex justify-content-between align-items-center"
        :class="moreBills ? 'mt-2' : 'mt-4'"
      >
        <h5 class="payer-section mb-0">
          {{ $t('payment.overpayment') }}
        </h5>
      </div>
      <hr class="mt-2">
      <PayerOverpayments
        v-if="details"
        :payer-id="id"
        @select="paymentId = $event; showPayment = true"
        @empty="hideOverpayments = true"
      />
    </template>

    <template v-if="!moreBalance">
      <div
        v-if="!moreBalance"
        class="d-flex justify-content-between align-items-center"
        :class="moreBills ? 'mt-2' : 'mt-4'"
      >
        <h5 class="payer-section mb-0">
          {{ $t('bill.toPay') }}
        </h5>
        <div
          v-if="moreBills"
          @click="moreBills = false"
        >
          <i class="fas fa-times secondary-icon" />
        </div>
        <div
          v-else
          class="px-2"
        >
          <BillsLinkShare :payer-id="id" />
        </div>
      </div>
      <hr class="mt-2">

      <PayerBillsDetails
        v-if="details"
        :payer-id="id"
        :more="moreBills"
        @select="payerBill = $event; showPayerBill = true"
        @more="moreBills = true"
      />
    </template>

    <template v-if="!moreBills">
      <div
        class="d-flex justify-content-between align-items-center"
        :class="moreBalance ? 'mt-2' : 'mt-4'"
      >
        <h5 class="payer-section mb-0">
          {{ $t('billingScopes.billingScopes') }}
        </h5>
        <div
          v-if="moreBalance"
          @click="moreBalance = false"
        >
          <i class="fas fa-times secondary-icon" />
        </div>
      </div>
      <hr class="mt-2">

      <PayerBalanceHistory
        v-if="details"
        :payer-id="id"
        :balance="balance"
        :more="moreBalance"
        @more="moreBalance = true"
      />
    </template>

    <div v-if="!focus">
      <div class="d-flex justify-content-between align-items-center mt-4">
        <h5 class="payer-section mb-0">
          {{ $t('payers.activation') }}
        </h5>
        <div class="px-2">
          <i
            class="fas secondary-icon smaller-icon"
            :class="!showActivationEmailForm ? 'fa-user-plus' : 'fa-xmark'"
            @click="showActivationEmailForm = !showActivationEmailForm"
          />
        </div>
      </div>
      <hr class="mt-2">
      <PayerActivation
        v-if="details"
        :payer-id="id"
        :activation-email-form="showActivationEmailForm"
        :property-values="details.properties"
        :invites="(details.invites || [])"
        @close-custom="showActivationEmailForm = false"
        @invited="inviteAdded($event)"
        @removed="inviteRemoved($event)"
      />
    </div>

    <PModal v-model="showPayment">
      <PaymentDetails
        :payment-id="paymentId"
        @update="refresh"
      />
    </PModal>
    <PModal v-model="showPayerBill">
      <BillPayerDetails
        v-if="details"
        v-bind="payerBill"
        :payer="details"
        show-bill-header
        @refresh="refresh"
        @cancel="showPayerBill = false"
      />
      <div v-else>
        <Loader />
      </div>
    </PModal>
  </div>
  <div v-else-if="edit">
    <PayerForm
      :payer="details"
      @updated="updateDetails($event); $emit('updated', $event)"
      @close="edit = false"
      @archive="$emit('archive', $event)"
    />
  </div>
  <div v-else-if="audit">
    <PayerPropertyAudit
      :payer="details"
      @close="audit = false"
    />
  </div>
</template>

<script>
import PaymentDetails from '@/components/payments/PaymentDetails';
import { mapActions } from 'vuex';
import BillPayerDetails from '../bills/BillPayerDetails';
import BillsLinkShare from './BillsLinkShare';
import PayerForm from './PayerForm';
import PayerHeader from './PayerHeader';
import PayerPropertyAudit from './PayerPropertyAudit';
import types from './payer-types';
import PayerActivation from './sections/PayerActivation';
import PayerBalance from './sections/PayerBalance';
import PayerBalanceHistory from './sections/PayerBalanceHistory';
import PayerBillsDetails from './sections/PayerBillsDetails';
import PayerDataSummary from './sections/PayerDataSummary';
import PayerOverpayments from './sections/PayerOverpayments';

export default {
  props: {
    id: String,
    clientPage: String,
    name: String,
    type: String,
    code: String,
    number: Number,
    balance: Array,
  },
  data: () => ({
    page: 'details',
    details: null,
    edit: false,
    audit: false,
    moreBills: false,
    moreBalance: false,
    showPayerBill: false,
    payerBill: null,
    hideOverpayments: false,
    paymentId: null,
    showActivationEmailForm: false,
    showPayment: false,
  }),
  components: {
    PayerBillsDetails,
    PayerBalanceHistory,
    PayerDataSummary,
    PayerActivation,
    PayerPropertyAudit,
    PayerOverpayments,
    PayerBalance,
    BillPayerDetails,
    BillsLinkShare,
    PaymentDetails,
    PayerHeader,
    PayerForm,
  },
  computed: {
    focus() {
      return this.moreBills || this.moreBalance;
    },
    icon() {
      if (!this.details) return null;
      return types.find((x) => x.key === this.details.type);
    },
  },
  watch: {
    page(p) {
      this.$emit('update:clientPage', p);
    },
  },
  methods: {
    ...mapActions(['getPayerDetails']),
    inviteAdded(invite) {
      const invites = (this.details.invites || []).concat([invite]);
      this.$set(this.details, 'invites', invites);
      this.$emit('updated', this.details);
    },
    inviteRemoved(invite) {
      const invites = (this.details.invites || []).filter((x) => x.email !== invite);
      this.$set(this.details, 'invites', invites);
      this.$emit('updated', this.details);
    },
    updateDetails(payerInfo) {
      this.details = {
        ...this.details,
        ...payerInfo,
      };
    },
    refresh() {
      this.details = null;
      this.$nextTick(() => {
        this.request();
      });
    },
    request() {
      this.getPayerDetails({
        params: {
          payerId: this.id,
          query: {
            withBills: true,
            withActivation: true,
          },
        },
      })
        .then(({ data }) => {
          this.details = data;
          this.$emit('updated', this.details);
        });
    },
  },
  created() {
    this.page = this.clientPage;
    this.request();
  },
};
</script>

<style lang="scss" scoped>

  .comment {
    font-weight: 500;
    font-size: 12px;
    padding-left: 20px;
  }

  .client-btn {
    border-radius: 20px;
  }

  .close {
    cursor: pointer;
  }

  .payer-section {
    font-size: 16px;
    font-weight: 600 !important;
  }

  .smaller-icon {
    font-size: 0.85rem;
  }

  .after-deadline-payments ::v-deep .payment-row {
    box-shadow: 0 0 1px 1px $red;
    transition: box-shadow 200ms;
    &:hover {
      box-shadow: 0 0 4px 1px $red;
    }
  }
</style>
